/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { app as appConfig } from '../../configs';
import QuotationPrint from '../../components/PdfFile/quotation';
import { ExportExcelContainer } from '../../components/ExcelDownload';
import { ProductAllDataUtil } from '../../utils/modelutils';
import ExportExcelPos from '../../components/ExcelDownload/ExportExcelPos';
import ProductAllPos from '../../utils/modelutils/ProductAllPos';

const POSManagement = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const productTransaction = useSelector((state) => state.productTransaction);
  const setting = useSelector((state) => state.setting);

  const history = useHistory();
  // const product = useSelector((state) => state.product);
  console.log('productTransaction', productTransaction);

  // Setting React hook form

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  // const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  // const [endDate, setEndDate] = useState();
  const [total, setTotal] = useState(undefined);
  const transactionType = 'pos';

  useEffect(
    () => {
      dispatch(actions.productTransactionReset());
      dispatch(
        actions.productTransactionAll({
          name,
          page,
          size,
          selectDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          transactionType,
        }),
      );
      dispatch(actions.settingAll());
      return () => {};
    },
    [name, page, size, startDate, endDate],
    // startDate,
  );

  useEffect(() => {
    setTotal(productTransaction?.total);
    return () => {};
  }, [productTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/pos/exports/${id}`);
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(await dispatch(actions.productTransactionDelete(id)));
        await dispatch(
          actions.productTransactionAll({ name, page, size, transactionType }),
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePrintQuotation = (data) => {
    console.log('data print', data);
    console.log('setting', setting);

    const ArrayProductList = _.map(data?.order, (eachOrder) => {
      if (!eachOrder?.product?.option_type) {
        return { ...eachOrder, option: undefined };
      }
      return eachOrder;
    });

    console.log('ArrayProductList', ArrayProductList);

    QuotationPrint(data, setting, ArrayProductList);
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div className="my-auto mr-1">
        <ExportExcelPos
          columnList={ProductAllPos?.columns}
          currentData={productTransaction?.rows}
          disableAllExport
          dataAPIEndPoint="product-transaction"
          dataQuery={{
            page: 1,
            size: appConfig?.maxFetchSize,
            DashBoardPage: false,
            type: 'pos',
            selectDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
            endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
          }}
          sheetName="รายการสั่งซื้อหน้าร้าน"
          spreadedColumn="order"
        />
      </div>
      <div>
        <Link to="/pos/main-front">
          <Button startIcon={<AddBoxIcon />} variant="contained">
            เพิ่ม
          </Button>
        </Link>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="w-full flex justify-end mx-4 md:w-1/2">
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                renderInput={(props) => <TextField {...props} size="small" />}
                label="เลือกวันที่"
                inputFormat={'DD MMMM BBBB '}
                value={startDate ? dayjs(startDate) : null}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                renderInput={(props) => <TextField {...props} size="small" />}
                label="ถึงวันที่"
                inputFormat={'DD MMMM BBBB '}
                value={endDate ? dayjs(endDate) : null}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">หมายเลขบิล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คู่ค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคารวม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.rows) ? (
                productTransaction.rows
                  .filter((row) => row.product_delete_status !== true || '')
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    >
                      <TableCell component="th" scope="row">
                        {(page - 1) * size + index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.quotation && (
                          <div
                            className="bg-yellow-400 text-center cursor-pointer hover:bg-yellow-500"
                            onClick={() => handlePrintQuotation(row)}
                          >
                            ใบเสนอราคา
                          </div>
                        )}
                        <div>{row?.bill_no}</div>
                        <div className="mt-1">
                          {row?.payment_type === 'cash' && 'เงินสด'}
                        </div>
                        <div className="mt-1">
                          {row?.payment_type === 'transfer' && 'โอน'}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">{`${
                          row?.customer?.name || '-'
                        }`}</div>
                        <div className="">{`${
                          row?.customer?.type?.name || '-'
                        }`}</div>
                      </TableCell>
                      <TableCell>
                        {row?.order.map((eachOrder, _index) => (
                          <>
                            <div key={_index} className="w-full py-1 flex">
                              <div className="font-bold flex">
                                <p className="pr-1 text-red-500">
                                  {eachOrder?.amount} X{' '}
                                </p>
                                {eachOrder?.product?.name}
                                {eachOrder?.product?.option_type && (
                                  <a className="pl-1">
                                    ( {eachOrder?.option?.name} )
                                  </a>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">
                          {row?.total_price_offline_out_before
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </TableCell>
                      <TableCell>{`${dayjs(row?.date).format(
                        'D MMM BBBB ',
                      )}`}</TableCell>
                      <TableCell>
                        <div className="flex flex-row flex-wrap gap-1">
                          <Button
                            variant="contained"
                            color={'secondary'}
                            startIcon={<DescriptionIcon />}
                            size={'small'}
                            onClick={() => handleToInfoPage(row.id)}
                          >
                            รายละเอียด
                          </Button>
                          <Button
                            variant="contained"
                            color={'error'}
                            size={'small'}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              handleDelete(row?.id);
                            }}
                          >
                            ลบ
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (
    productTransaction.isLoading ||
    !productTransaction.rows ||
    setting.isLoading
  ) {
    return <Loading />;
  }
  if (
    !productTransaction.isLoading &&
    productTransaction.isCompleted &&
    !setting.isLoading &&
    setting.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={productTransaction?.message} />;
};

POSManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

POSManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default POSManagement;
